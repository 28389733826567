import React from "react";
import { Helmet } from 'react-helmet';

import logo from 'assets/ogimage.jpg';
import { useSiteMetadata } from "common/hooks";

type Meta = {
  name?: string,
  content: any,
  property: string,
}

type SeoProps = {
  description?:string,
  language?:string,
  url?:string,
  meta?: Meta[],
  title:string
};

function Seo({ description, language, meta, title, url }: SeoProps) {

  const siteData = useSiteMetadata()

  const metaDescription = description || siteData.description
  const metaUrl = url || logo

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        language,
      }}
      title={title}
      titleTemplate={`%s | ${siteData.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: siteData.tags.concat(', '),
        },
        {
          name: `theme-color`,
          content: siteData.themeColor,
        },
        {
          name: `image`,
          content: metaUrl,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaUrl,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteData.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        // @ts-ignore
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `ro`,
  meta: [],
  description: ``,
}


export default Seo
